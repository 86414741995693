.date {
	&-sep {
		display: inline-block;
		margin: 0 3%;
	}

	@include breakpoint( xlarge ) {
		&-sep {
			margin: 0 4%;
		}
	}
}