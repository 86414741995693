.top-page-header {
	$header-top-padding: $small-spacing-section-tiny;

	padding-top: $header-top-padding;
	margin-bottom: $small-spacing-section-tiny;

	.window-scrolled &,
	.menu-opened & {
		// position: fixed;
		// width: 100%;
		// z-index: 13;
	}

	&__inner {
		// @include xy-grid-container();
		@include xy-grid();
	}

	&__claimpart {
		@include xy-cell( 5 );
	}

	&__shorttitle {
		text-decoration: none;
		
		.window-scrolled &,
		.menu-opened & {
			position: fixed;
			width: 100%;
			z-index: 13;
		}

	}

	&__controlspart {
		@include xy-cell( 7 );
		position: absolute;
		top: 5px;
		right: 10px;
		z-index: 14;
		margin: 0;
		display: flex;
		justify-content: flex-end;

		.window-scrolled &,
		.menu-opened & {
			position: fixed;
			z-index: 13;
		}
	}

	&__whatisit {
		margin-bottom: 0;
		text-transform: uppercase;

		.menu-opened & {
			color: $white;
		}
	}

	&__fulltitle {
		display: none;

		.window-scrolled & {
			display: none;
		}
	}

	&__microtitle {
		.window-scrolled & {
			display: none;
		}
	}

	&__shorttitle {
		display: none;

		.window-scrolled & {
			display: inline;
		}
	}

	@include breakpoint( medium ) {
		$header-top-padding: 1.2rem;
		padding-top: $header-top-padding;
		margin-bottom: rem-calc( 16 );

		.menu-opened & {
			position: static;
		}

		&__fulltitle {
			display: inline;

			.window-scrolled & {
				display: none;
			}
		}

		&__microtitle {
			display: none;
		}

		&__claimpart {
			@include xy-cell( 9 );
		}

		&__whatisit {
			.menu-opened & {
				color: $sensorium-primary;
			}
		}

		&__controlspart {
			@include xy-cell( 3 );
			top: $header-top-padding;
			right: rem-calc( 20 );
			margin: 0;
			margin-top: rem-calc( -7 );
		}
	}
}