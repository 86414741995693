.showcasesection {
	margin-bottom: $small-spacing-section-tiny;
	.cell {
		display: flex;
		flex-flow: column nowrap;
		justify-content: flex-end;
	}

	&--ordinary {
		.cell {
			justify-content: flex-start;
		}
	}

	&--last {
		margin-bottom: $small-spacing-section-huge;

	}

	@include breakpoint( medium ) {
		margin-bottom: $spacing-section-tiny;

		p:last-child {
			margin-bottom: 0;
		}

		&--last {
			margin-bottom: $spacing-section-huge;
		}

		&--theme,
		&--reverseorder {
			margin-bottom: 0.2rem;
			.cell {
				order: 2;
			}

			.cell:last-of-type {
				order: 1;
			}
		}
	}
}