.partnerlist {

	@include grid-layout( 2, '.partnerlist-logo', 0);
	overflow: auto;
	
	&-title {
		margin-bottom: rem-calc( 20 );

	}

	&-logo {
		display: block;
		margin-bottom: rem-calc( 20 );

		&__img {
			max-width: 80%;
		}
	}

	@include breakpoint( medium ) {
		@include grid-layout( 3, '.partnerlist-logo' );
		margin-bottom: rem-calc( 60 );

		&-title {
			margin-bottom: $spacing-section-tiny;

			& + div {
			}
		}

		&-logo {
			margin-bottom: rem-calc( 20 );

			&__img {
				max-height: rem-calc( 95 );
			}

			.partnerlist &--major {
				width: 45%;
			}

			&--major &__img {
				max-width: 90%;
				max-height: none;
			}
		}
	}
}