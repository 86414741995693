.menu-button {
	display: flex;
	text-transform: uppercase;
	align-items: center;

	&.clear:hover,
	&.clear:focus {
		color: $sensorium-primary;

		.menu-opened & {
			color: $white;
		}
	}

	&__text {
		&--close {
			display: none;
		}

		.menu-opened & {
			display: none;
			color: $white;
			
			&--close {
				display: inline;
				color: $white;
			}
		}
	}

	&__icon {
		display: none;
	}

	@include breakpoint( medium ) {
		&__icon {
			display: inline-block;
			width: 0.72em;
			height: 0.72em;
			margin-right: 0.18em;
			position: relative;

			span {
				display: block;
				width: 100%;
				height: 0.08em;
				background-color: $sensorium-primary;
				position: absolute;

				&:nth-child( 3 ) {
					bottom: 0;
				}

				&:nth-child( 2 ) {
					top: 50%;
					transform: translateY(-0.04em );
				}

				.menu-opened & {
					background-color: $white;
				}

				.menu-opened &:nth-child( 1 ),
				.menu-opened &:nth-child( 3 ) {
					display: none;
				}

			}

		}

		&__text {
			&--close {
				display: none;
			}

			.menu-opened & {
				display: inline;
				
				&--close {
					display: none;
				}
			}
		}
	}
}