.newslist {
	&__item {
		@include xy-grid();
		margin-bottom: rem-calc( 60 );

		&:last-of-type {
			margin-bottom: 0;
		}
		
		p {
			// margin-bottom: 0;
		}
	}

	&__img {
		@include xy-cell( 6 );
		margin-left: 0;

	}

	&__content {
		@include xy-cell( 6 );
		margin-right: 0;

	}

	&__href {
		text-decoration: none;
		display: block;
		margin-bottom: rem-calc( 20 );

		&::hover {
			text-decoration: underline;
		}
	}

	&__date {
		text-transform: uppercase;
	}

	&__location {
		text-transform: uppercase;
	}

	&__summary {
		margin-bottom: rem-calc( 20 );
	}

	&__showall {
		text-align: center;
		margin-top: rem-calc( 60 );
		margin-bottom: 0;
		text-transform: uppercase;
	}

	@include breakpoint( medium ) {
		&__item {
			// @include xy-gutters( $grid-column-gutter );
		}
		
		&__img {
			@include xy-cell( 4, $gutters: $grid-column-gutter );
			// @include xy-cell-gutters( $grid-column-gutter );
			// width: calc(33.33333% - 1.25rem); //
		}

		&__content {
			@include xy-cell( 8, $gutters: $grid-column-gutter );
		}

	}

}