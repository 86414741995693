/*
        This font software is the property of Commercial Type.

        You may not modify the font software, use it on another website, or install it on a computer.

        License information is available at http://commercialtype.com/eula
        For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

        Copyright (C) 2020 Schwartzco Inc.
        License: 2002-YPTPUY     
*/

@font-face {
  font-family: 'Haas Grot Disp Web';
  src: url('../fonts/NeueHaasGrotDisp-55Roman-Web.woff2') format('woff2'),
       url('../fonts/NeueHaasGrotDisp-55Roman-Web.woff') format('woff');
  font-weight:  500;
  font-style:   normal;
  font-stretch: normal;
}

.text-upper {
  text-transform: uppercase;
}


//flexible typo via https://css-tricks.com/snippets/css/fluid-typography/
@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

$min_vw: 375px;
$min_vw_social: 640px;
$max_vw: 1920px;
$min_font_bigtype: 68px;
$max_font_bigtype: 356px;
$min_font_maintype: 15px;
$max_font_maintype: 34px;
$min_font_bodytype: 15px;
$max_font_bodytype: 30.1px;
$min_font_menutype: 40px;
$max_font_menutype: 17.5px;
$max_font_social: 32px;
// $min_font: 33px;
// $max_font: 120px;

.big-type {
  @include fluid-type($min_vw, $max_vw, $min_font_bigtype, $max_font_bigtype);
  line-height: 0.794;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  text-align: center;
  justify-content: center;
  margin-bottom: $small-spacing-section-tiny;
  padding-right: rem-calc( 10 );
  padding-left: rem-calc( 10 );
  overflow: hidden;

  @include breakpoint( medium ) {
    padding-right: rem-calc( 20 );
    padding-left: rem-calc( 20 );
    margin-bottom: $spacing-section-tiny;
  }
}

.main-type,
.main-type p {
  @include fluid-type($min_vw, $max_vw, $min_font_maintype, $max_font_maintype);
  line-height: 1;
  margin-bottom: rem-calc( $min_font_maintype );
  // margin-bottom: $small-spacing-section-tiny;

  // @include breakpoint( medium ) {
  //   margin-bottom: rem-calc( $max_font_maintype );
  // }
}

h2,
h3,
h4,
h5,
ul li,
.body-type,
.navigation,
.navigation a,
.off-canvas__social a,
.off-canvas__langswitcher,
p {
  @include fluid-type($min_vw, $max_vw, $min_font_bodytype, $max_font_bodytype);
  line-height: 1;
}

.navigation a {
  @media screen and (max-width: $min_vw_social) {
    font-size: rem-calc( $min_font_menutype );
  }
}

h2,
h3,
h4 {
  text-transform: uppercase;

  @include breakpoint( medium ) {
    margin-bottom: 0;
  }
}

// .navigation a {
//   @include fluid-type($min_vw, $max_vw, $min_font_menutype, $max_font_menutype);
//   line-height: 1;

//   // do 640 je velke
//   // od 640

//   // 375 a menej - 40

//   // 376 - 1920 sa zmensuje, no pri 1440 musi byt 25
//   // 1920  

// }

// .off-canvas__social a {
//   font-size: rem-calc( $min_font_bodytype );
   
//    // @include fluid-type($min_vw_social, $max_vw, $min_font_bodytype, $max_font_social);
//    @include fluid-type($min_vw, $max_vw, $min_font_menutype, $max_font_menutype);

//    font-size: $min_font_bodytype;
//    @media screen and (min-width: $min-vw) {
//      font-size: calc(#{$min_font_menutype} + #{strip-unit($max_font_menutype - $min_font_menutype)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
//    }
//    @media screen and (min-width: $max-vw) {
//      font-size: $max_font_menutype;
//    }
// }