.off-canvas {
	padding-left: $small-spacing-section-tiny;
	padding-bottom: $small-spacing-section-tiny;

	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;

	&__social {
		margin-bottom: 0;
		a {
			color: $white;
			text-transform: uppercase;
			text-decoration: none;
		}
	}

	&__langswitcher {
		list-style-type: none;
		margin: 0;
		padding: 0;

		a {
			color: $white;
			text-decoration: none;
			text-transform: uppercase;

			&.active {
				display: none;
			}
		}


	}

	@include breakpoint( medium ) {
		padding-left: rem-calc( 20 );
		justify-content: flex-start;

		&__social {
			&:last-of-type {
				margin-bottom: 1em;

			}
		}

		&__langswitcher {
			margin-bottom: 1em;
		}
	}
}